//
// Toolbar
//
.darkroom-toolbar {
  display: block;
  position: absolute;
  top: -45px;
  left: 0;
  background: #444;
  height: 40px;
  min-width: 40px;
  z-index: 99;
  border-radius: 2px;
  white-space: nowrap;
  padding: 0 5px;

  // Triangle
  &:before {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #444;
  }
}

//
// Button Group
//
.darkroom-button-group {
  display: inline-block;
  margin: 0;
  padding: 0;
  // border-right: 1px solid #777;

  &:last-child {
    border-right: none;
  }
}


//
// Button
//
.darkroom-button {
  box-sizing: border-box;
  background: transparent;
  border: none;
  outline: none;
  padding: 2px 0 0 0;
  width: 40px;
  height: 40px;

  &:hover {
    cursor: pointer;
    background: #555;
  }
  &:active {
    cursor: pointer;
    background: #333;
  }

  &:disabled .darkroom-icon {
    fill: #666;
  }
  &:disabled:hover {
    cursor: default;
    /*cursor: not-allowed;*/
    background: transparent;
  }
  &.darkroom-button-active .darkroom-icon {
    fill: #33b5e5;
  }
  &.darkroom-button-hidden {
    display: none;
  }
  &.darkroom-button-success .darkroom-icon {
    fill: #99cc00;
  }
  &.darkroom-button-warning .darkroom-icon {
    fill: #FFBB33;
  }
  &.darkroom-button-danger .darkroom-icon {
    fill: #FF4444;
  }
}

//
// Icon
//
.darkroom-icon {
  width: 24px;
  height: 24px;
  fill: #fff;
}
