.darkroom-container {
  position: relative;
}

.darkroom-image-container {
  top: 0;
  left: 0;
}

.darkroom-image-container img {
  // display: none;
}
